const SimpleBlogList = ({articles}) =>{
    return (
        <div className="simple-blog-list">
            {articles && articles.map(
              article => (
                <div className="articleDetail" key={article.id}> 
                    <h2> { article.title } </h2>
                    <h3> { article.body } </h3>
                    <h5> Written by { article.author } </h5>
                </div>
              ))
            }
        </div>
    );
};


export default SimpleBlogList;