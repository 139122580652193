import React, {useEffect, useState} from "react";
import Recipe from "./Recipe";
import './App.css';
import Nav from './Nav';
import About from './About';
import Blog from './Blog';
import Drinks from './Drink';
import Home from './Home';
import BlogDetail from './BlogDetail';
import ItemDetail from './ItemDetail';
import CocktailRecipe from "./CocktailRecipe";
import CreateBlog from "./CreateBlog";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


const App = () => {
  const APP_ID = '900da95e';// const APP_ID = '960a3e65';
  const APP_KEY = '40698503668e0bb3897581f4766d77f9';// const APP_KEY = '3b23f9ee9223638a10e320a466fbb415';
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    getRecipes();
  },[query]);

  const REQUEST_STR = `https://api.edamam.com/search?app_id=${APP_ID}&app_key=${APP_KEY}&q=${query}`;

  const getRecipes = async () => {
    const response = await fetch(REQUEST_STR);
    const data = await response.json();
    console.log(data);
    setRecipes(data.hits);
  };

  const updateSearch = e => {
    setSearch(e.target.value);
    console.log(search);
  }

  const getSearch = e => {
    e.preventDefault();
    setQuery(search);
    setSearch("")
  }

    return(
      <Router>
      <div className="App">

        <Nav />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          {/* TODO ADD ALPHABET SELECTOR*/}
          <Route path="/blog" component={Blog} />
          <Route path="/drinks" exact component={Drinks} />
          <Route path="/drinks/:id" component={ItemDetail} />
          <Route path="/cocktail/recipe/:id" component={CocktailRecipe} />
          <Route path="/blogs/:id" component={BlogDetail}>
            <BlogDetail/>
          </Route>
          <Route path="/createBlog" component={CreateBlog} />
        </Switch>
  
        <form onSubmit={getSearch} className="search-form">
          <input className="search-bar" type="text" value={search} onChange={updateSearch} />
            <button className="search-button" type="submit">
              Search
            </button>
        </form>
          <div className="recipes">
            {recipes.map(recipe => (
              <Recipe
              key={recipe.recipe.label} 
              title={recipe.recipe.label}
              calories={Math.round(recipe.recipe.calories)}
              image={recipe.recipe.image}
              ingredients={recipe.recipe.ingredients}
              />
            ))}
          </div>
      </div>
      </Router>
    );
}

export default App;
