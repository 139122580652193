import React from 'react';
import './App.js'
import {useState, useEffect} from 'react';
import BlogList from './BlogList';
import SimpleBlogList from './SimpleBlogList';
import './App.css';
import { Link } from 'react-router-dom';
import Recipe from './Recipe';

const Blog = () => {
    const [blogs, setBlog] = useState(null);
    const [name, setName] = useState('All Articles');
    const [articles, setArticles] = useState([]);

const handleDelete = (id) => {
    const newBlogs = blogs.filter(blog => blog.id !== id);
    setBlog(newBlogs);
}

useEffect(() => {
    fetch('http://localhost:8000/blog')
    .then(res => {
        return res.json();
    }).then((res) => {
        setArticles(res);
    })
    .then((data) => {
        setBlog(data);
        console.log(data);
        
    }, [])
}, []);

return (
    <div className="blog">
   
        {/* blogs && <SimpleBlogList blogs={blogs} title="All Blogs Title" handleDelete={handleDelete} /> */}
        <button onClick={() => setName('Filtered Articles')}>Change Name</button>
        <p>{name}</p>

        { articles && <SimpleBlogList articles={articles}/>} 

    </div>
    );

}

export default Blog;

