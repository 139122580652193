import React, {useState, useEffect} from 'react';
import './App.css'
// import {Link} from 'react-router-dom';
import style from './drinks.module.css'

function CocktailRecipe({match}){
    useEffect(() => {
        fetchItems();
        console.log('found456::' + match);
    }, []);

    const [items, setItems] = useState([]);

    const fetchItems = async () => {
        const data = await fetch(`https://www.thecocktaildb.com/api/json/v1/1/lookup.php?i=${match.params.id}`);
        const items = await data.json();
        console.log(items.drinks);
        setItems(items.drinks);
    
    };

    return (
        <div>
           <h3>Cocktail Instructions </h3>
           <h1>{match.params.id}</h1>
           {items.map(item => (
                <h1 key={item.strDrink}>
                <h1>{item.strDrink}</h1>
                <ul>
                    <b>{item.strMeasure1}</b>
                    <b>{item.strIngredient1}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure2}</b>
                    <b>{item.strIngredient2}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure3}</b>
                    <b>{item.strIngredient3}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure4}</b>
                    <b>{item.strIngredient4}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure5}</b>
                    <b>{item.strIngredient5}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure6}</b>
                    <b>{item.strIngredient6}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure7}</b>
                    <b>{item.strIngredient7}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure8}</b>
                    <b>{item.strIngredient8}</b>
                </ul>
                <ul>
                    <b>{item.strMeasure9}</b>
                    <b>{item.strIngredient9}</b>
                </ul>
                <h7>{item.strInstructions}</h7>
                
                </h1>
            ))};
        </div>
    );
}
export default CocktailRecipe;