import React, {useState, useEffect} from 'react';
import './App.css'
import {Link} from 'react-router-dom';

function ItemDetail({match}){
    useEffect(() => {
        fetchItems();
        console.log('found123::' + match);
    }, []);

    const [items, setItems] = useState([]);

    const fetchItems = async () => {
        const fetchItems = await fetch(`https://www.thecocktaildb.com/api/json/v1/1/search.php?s=${match.params.id}`);
        //https://www.thecocktaildb.com/api/json/v1/1/search.php?s=margarita
        const items = await fetchItems.json();
        console.log(items);
        setItems(items.drinks);
    };

    return (
        <div>
           <h3>Item Detail for </h3>
           <h1>{match.params.id}</h1>
           {items.map(item => (
                <h1 key={item.idDrink}>
                <Link to={`/cocktail/recipe/${item.idDrink}`}>{item.strDrink}</Link>
                </h1>
            ))};
        </div>
    );
}
export default ItemDetail;