import { useParams } from 'react-router-dom'
// import useFetch from './useFetch';

const BlogDetail = () => {
    const { id } = useParams();
    // const { data: blog, error, isPending } = useFetch('http://localhost:8000/blogs/' + id);
    
    // { isPending && <div>Loading...</div>}
    // { error && <div>{ error }</div>}
    // { blog && (
    //    <article><h2>{ blog.title }</h2><p>Written by { blog.author } </p><p>{ blog.body }</p></article>
    // )}
    return (
        <div className="blogDetail">
            <h2>Blog details</h2>
        </div>
)};

export default BlogDetail;