import React from 'react';
import './App.css'
import { Link } from 'react-router-dom'


function Nav(){
    const navStyle = {
        color: 'white',
        border: '20px'
    };

    return (
        <nav>
            <h3>Logo</h3>
            <h3 className='nav-links'>
                <Link style={navStyle} to="/about"> 
                    <li>About</li>                
                </Link>
                <Link style={navStyle} to="/drinks">
                    <li>Drinks</li>
                </Link>
                <Link style={navStyle} to="/blog">
                    <li>Blog</li>
                </Link>
                <Link style={navStyle} to="/createBlog">
                    <li>Create Blog</li>
                </Link>
            </h3>
        </nav>
    );
}
export default Nav;