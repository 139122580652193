import React from 'react';
import { useState, useEffect } from 'react';
import './App.js'
import SimpleRecipeList from './SimpleRecipeList.js';

const Home = () => {
    const [recipes, setRecipes] = useState(null);

useEffect(() => {
    fetch('http://localhost:8000/recipe')
    .then(res => {
        return res.json();
    }).then((res) => {
        setRecipes(res);
    }, [])
}, []);

return (
    <div className="home"> 
        <p> HOME PAGE!!! TODO </p>

        { recipes && <SimpleRecipeList recipes={recipes}/>}
    
    </div>

    );
}
export default Home;