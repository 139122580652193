import { useState } from "react"
import { useHistory } from "react-router-dom"


const CreateBlog = () => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [author, setAuthor] = useState('cuisineSavvy');
    const [isPending, setIsPending] = useState(false);
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        const blog = {title, body, author}

        setIsPending('true');
        fetch('http://localhost:8000/blog/', {
            method: 'POST',
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify(blog)
        }).then(() => {
            console.log('new post added')
            setIsPending('false');
            history.go(1);
        })
    }
    
    return (
        <div className="createBlog">
            <h2>Add a new blog</h2>
            <form>
                <label>Blog title:</label>
                <input type="text" required value={ title } onChange={(e) => setTitle(e.target.value)} />
                <label>Blog body</label>
                <textarea type="text" required value={ body } onChange={(e) => setBody(e.target.value)}></textarea>
                <label>Blog author</label>
                <select value={author} onChange={(e) => setAuthor(e.target.value) }>
                    <option value="cuisineSavvy">Cuisine Savvy</option>
                    <option value="liveDataJournal">Live Data Journal</option>
                </select>
                { !isPending && <button>Add Blog</button>}
                { isPending && <button disabled>Adding...</button>}
            </form>

            <p>{ title }</p>
            <p>{ body }</p>
            <p>Author: { author }</p>
        </div>
    );
}

export default CreateBlog