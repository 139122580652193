import React from 'react';
import './App.js'

function About(){
    return (
        <div>
            <h1>About Page</h1>
        </div>
    );
}
export default About;