import style from './simpleRecipeList.module.css'

const SimpleRecipeList = ({recipes}) =>{

  function Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className={style.page}>
            <div className={style.title}> RECIPES </div>
            { recipes && recipes.map(
              recipe => (
                <div className={style.recipe} key={recipe.id}>
                    <h2> { recipe.title } </h2>
                    <h2> <u>Ingredients</u> </h2>
                    <h4> { recipe.ingredients.map( item => (
                      <div>
                        <h3> { Capitalize(item[0]) + ":" } </h3>
                        <h4> { item[1] } </h4>
                      </div>
                    )) } </h4>
                    <h2> <u>Instructions</u> </h2>
                    <h3> { recipe.body.map( item => (
                      <div>
                        <h3>{ Capitalize(item[0]) + ") " + item[1] }</h3>
                      
                      </div>
                      )) } </h3>
                    <h5> Written by { recipe.author } </h5>
                </div>
              ))
            }
        </div>
    );
};


export default SimpleRecipeList;