import React, {useState, useEffect} from 'react';
import './App.css';
import {Link} from 'react-router-dom';
import style from './drinks.module.css'

function Drinks(){
    useEffect(() => {
        fetchItems();
    }, []);

    const [items, setItems] = useState([]);

    const fetchItems = async () => {
        const data = await fetch('https://www.thecocktaildb.com/api/json/v1/1/search.php?f=l');
        const items = await data.json();
        console.log(items.drinks);
        setItems(items.drinks);
    
    };

    return (
        <div>
            <h1>Beverage Page</h1>
            {items.map(item => (
                <h1 key={item.strDrink}>
                <Link to={`/drinks/${item.strDrink}`}>{item.strDrink}</Link>
                </h1>
            ))};
        </div>
    );
}
export default Drinks;